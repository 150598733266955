<template>
  <b-modal
    id="modal-add-catalog-group"
    title="Add Group"
    centered
    hide-header-close
  >
    <b-input placeholder="Group Name" v-model="groupName"></b-input>
    <template #modal-footer>
      <b-button class="btn-wz-cancel" @click="hideModal">Cancel</b-button>
      <b-button class="btn-wz-proceed" :disabled="!groupName" @click="addCatalogGroup">Save</b-button>
    </template>
  </b-modal>
</template>

<script>
import { toaster } from "@/_helpers";

export default {
  name: "ModalAddGroupCatalog",
  data() {
    return {
      groupName: "",
    }
  },
  methods: {
    async addCatalogGroup() {
      try {
        if(!this.groupName) return
        const data = {
          name : this.groupName
        }
        const res = await this.$api.catalog.addCatalogGroup(data);
        if (res.status === 200) {
          toaster.make(res.data.message, "success")
          this.hideModal()
          this.$emit('refreshCatalogGroup')
          this.groupName = ""
        }
        else toaster.make(res.data.message, "danger")
      } catch (error) {
        if (error.response) toaster.make(error.response, 'danger')
        console.error(error)
      }
    },
    hideModal() {
      this.$bvModal.hide('modal-add-catalog-group')
    }
  },
};
</script>
<style scoped>
button {
  padding: 8px 12px;
  text-transform: uppercase;
  font-size: 12px !important;  
  letter-spacing: .5px !important;
}
</style>
